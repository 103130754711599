import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import classnames from 'classnames'
import { useWeb3Modal } from '@web3modal/react'

const LeftBar = ({ isExpanded, onHamburgerClick }) => {
  const [showLinks] = useState(true)
  const [isHovered, setIsHovered] = useState(false)
  const { address, isConnected } = useAccount()
  const [firstConnect, setFirstConnect] = useState(false)
  const { chain } = useNetwork()

  const handleHover = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  let currentPath = window.location.pathname.toLowerCase()
  const textClass = classnames('left-bar-text', {
    'left-bar-text-active': isExpanded || isHovered
  })

  const { open } = useWeb3Modal()
  const onConnect = async () => {
    await open()
  }

  const onConnectWallet = async () => {
    await open()
    setFirstConnect(true)
  }

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true) reloadWindow()
  }, [isConnected, firstConnect])

  const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()
  useEffect(() => {
    const switchChain = async () => {
      try {
        switchNetwork?.(43114)
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true) {
      if (chain?.id !== 43114) switchChain()
    }
  }, [isConnected, chain?.id, switchNetwork])

  return (
    <div
      className={`left-bar ${isExpanded ? 'left-bar-expanded' : ''}`}
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
      onClick={onHamburgerClick}
    >
      <ul className="left-bar-links">
        {(isHovered || showLinks) && (
          <>
            <li>
              <Link to="/AllLaunches" className="left-bar-link">
                <span
                  className={
                    currentPath === '/' || currentPath === '/alllaunches'
                      ? 'listSelected ' + textClass
                      : 'listUnselected ' + textClass
                  }
                >
                  Home
                </span>
              </Link>
            </li>
            <li>
              <Link to="/CreateChad" className="left-bar-link">
                <span
                  className={
                    currentPath === '/createchad'
                      ? 'listSelected ' + textClass
                      : 'listUnselected ' + textClass
                  }
                >
                  Launch
                </span>
              </Link>
            </li>
            <li>
              <Link to="/profile" className="left-bar-link">
                <span
                  className={
                    currentPath.startsWith('/profile')
                      ? 'listSelected ' + textClass
                      : 'listUnselected ' + textClass
                  }
                >
                  Profile
                </span>
              </Link>
            </li>
            <li>
              <a
                href="https://t.me/ChadPumpFun"
                target="_blank"
                rel="noreferrer"
                className="left-bar-link"
              >
                <span className={textClass}>Telegram</span>
              </a>
            </li>
            <li>
              <a
                href="https://x.com/ChadPumpFun"
                target="_blank"
                rel="noreferrer"
                className="left-bar-link"
              >
                <span className={textClass}>X.com</span>
              </a>
            </li>
          </>
        )}
      </ul>
      <div className="navConnectButtonMobileBox">
        {!isConnected ? (
          <button
            className="navConnectButton"
            type="submit"
            onClick={() => {
              onConnectWallet()
            }}
          >
            Connect<span className="navWallet"> Wallet</span>
          </button>
        ) : (
          <section>
            <div className="ChainGroupButton">
              {chain?.id === 43114 ? (
                <button
                  className="navConnectButton"
                  type="submit"
                  onClick={() => onConnect()}
                >
                  {address?.slice(0, 4) + '...' + address?.slice(-4)}
                </button>
              ) : (
                <button
                  className="navConnectButton"
                  type="submit"
                  onClick={() => switchNetwork?.(43114)}
                >
                  {'To Avax'}
                  {isLoading && pendingChainId === 43114 && ''}
                </button>
              )}
            </div>
          </section>
        )}
      </div>
    </div>
  )
}

export default LeftBar
