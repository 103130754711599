import { createPublicClient, http } from 'viem'
import { avalanche } from 'viem/chains'
import Web3 from 'web3'

export const multicallAddress = "0xfDdE68bF90f42b55185c769A521C563F85bC6582";
export const factoryAddress = "0x2bF693Da3E4142c147405f7E4C021F8bB32496aD";
export const contractAddress = "0x0000000000000000000000000000000000000000";
export const WAVAXAddress = "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7";
export const routerAddress = "0x60aE616a2155Ee3d9A68541Ba4544862310933d4";
export const defaultAddress = "0x0000000000000000000000000000000000000000";
export const publicClient = createPublicClient({
    chain: avalanche,
    transport: http()
})
const AVAX_PROVIDER_URL = 'https://avalanche-c-chain-rpc.publicnode.com'
export const AvaxWeb3 = new Web3(new Web3.providers.HttpProvider(AVAX_PROVIDER_URL))

export const imageUrl = 'https://pumpfun.zapto.org:13001/uploads/'

export const apiUrl = 'https://chadpump-api-699f97a0283e.herokuapp.com'

export const imageUploadUrl = 'https://pumpfun.zapto.org:13001/'