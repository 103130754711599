import React, { useState, useEffect, useCallback } from 'react'
import iconHamburger from '../icons/hamburger.svg'
import LeftBar from './LeftBar'
import { useAccount, useSwitchNetwork, useNetwork } from 'wagmi'
import { useWeb3Modal } from '@web3modal/react'
import ChadHeaderLink from '../components/ChadHeaderLink'
import { Link } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'
import { imageUrl, apiUrl } from '../utils/constants.ts'

const TopBar = animate => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [firstConnect, setFirstConnect] = useState(false)
  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()
  const handleHamburgerClick = () => {
    setIsExpanded(!isExpanded)
  }
  const [history, setHistory] = useState([])
  const [isPaused, setIsPaused] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 640) {
        setIsExpanded(false)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleDocumentClick = event => {
    const leftBarElement = document.querySelector('.left-bar')
    const hamburgerMenuElement = document.querySelector('.left-bar-menu')

    if (
      !leftBarElement.contains(event.target) &&
      event.target !== hamburgerMenuElement
    ) {
      setIsExpanded(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  const { open } = useWeb3Modal()
  const onConnect = async () => {
    await open()
  }

  const onConnectWallet = async () => {
    await open()
    setFirstConnect(true)
  }

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true) reloadWindow()
  }, [isConnected, firstConnect])

  const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()
  useEffect(() => {
    const switchChain = async () => {
      try {
        switchNetwork?.(43114)
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true) {
      if (chain?.id !== 43114) switchChain()
    }
  }, [isConnected, chain?.id, switchNetwork])

  useEffect(() => {
    const FetchData = async () => {
      try {
        await fetch(apiUrl + `/api/getHistory`, {
          method: 'GET'
        }).then(async res => {
          let data = await res.json()
          if (data.length > 0) {
            let history = []
            for (let i = 0; i < data?.length; i++) {
              let amount = data[i].amount
              let buyer = data[i].buyer
              let name = data[i].name
              let token = data[i].token
              let type = data[i].type
              let contract = data[i].contract
              let avatarUrl = imageUrl + 'profile-' + data[i].buyer + '.png'
              history.push({
                amount: amount,
                buyer: buyer,
                name: name,
                token: token,
                type: type,
                contract: contract,
                avatarUrl: avatarUrl
              })
            }
            // Duplicate items and set initial history
            const duplicatedHistory = [...history, ...history]
            setHistory(duplicatedHistory)
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
    FetchData()
  }, [])

  const handleMouseEnter = () => {
    setIsPaused(true)
  }

  const handleMouseLeave = () => {
    setIsPaused(false)
  }

  let currentPath = window.location.pathname

  const [animatedHistory, setAnimatedHistory] = useState([])

  useEffect(() => {
    if (animate) {
      if (animate) {
        setAnimatedHistory(history)
      } else {
        history()
      }
    }
  }, [animate, history])

  const animateList = useCallback(() => {
    if (animate && history.length > 0 && animatedHistory.length > 0) {
      const itemToMove = animatedHistory.shift()
      animatedHistory.push(itemToMove)
      setAnimatedHistory([...animatedHistory])
    }
  }, [animate, history, animatedHistory])

  useEffect(() => {
    let intervalId = null
    if (animate) {
      intervalId = setInterval(() => {
        animateList()
      }, 3000)
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [animate, animateList])

  const overlayOpacity = useSpring({
    from: { opacity: 0 },
    to: [{ opacity: 0.8 }, { opacity: 0.6 }, { opacity: 0.2 }, { opacity: 0 }],
    config: {
      duration: 100,
      easing: t => t * (2 - t)
    }
  })

  const AnimatedOverlay = () => (
    <animated.div
      style={{
        ...overlayOpacity,
        position: 'absolute',
        top: 0,
        left: 'calc(50% - 320px / 2)',
        width: '320px',
        height: '100%',
        backgroundColor: 'white',
        zIndex: 1,
        borderRadius: '10px'
      }}
    />
  )

  const firstItemShakeAnimation = useSpring({
    from: { transform: 'translateX(0px)' },
    to: [
      { transform: 'translateX(-20px)' },
      { transform: 'translateX(15px)' },
      { transform: 'translateX(-10px)' },
      { transform: 'translateX(5px)' },
      { transform: 'translateX(0px)' }
    ],
    config: {
      duration: 50, // Adjust duration as needed
      easing: t => t * (2 - t) // Easing function for a smooth start and end
    }
  })

  const getLinkStyle = path => {
    return currentPath.toLowerCase().startsWith(path.toLowerCase())
      ? {
        fontFamily: 'PingFang SC, Microsoft YaHei, Arial, sans-serif',
        color: 'white',
        fontSize: '20px',
        marginRight: '64px',
        borderBottom: '4px solid #4c00cb',
        paddingBottom: '2px'
      }
      : {
        fontFamily: 'PingFang SC, Microsoft YaHei, Arial, sans-serif',
        color: 'rgb(168, 160, 149)',
        fontSize: '20px',
        marginRight: '64px'
      }
  }

  const externalLinkStyle = {
    fontFamily: 'PingFang SC, Microsoft YaHei, Arial, sans-serif',
    color: 'rgb(168, 160, 149)',
    fontSize: '20px',
    marginRight: '64px'
  }
  
  return (
    <>
      <LeftBar
        isExpanded={isExpanded}
        onHamburgerClick={handleHamburgerClick}
      />
      <div className="top-header">
        <div className="top-trending">
          <div className="top-bar-items">
            {history.length > 0 ? (
              <div
                className="scroll-container"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div
                  className={`scroll-items ${isPaused ? 'paused' : ''}`}
                  style={{ width: '50%' }}
                >
                  {history.map(
                    (
                      { amount, buyer, name, token, contract, type, avatarUrl },
                      i
                    ) => (
                      <div className="scroll-item" key={i}>
                        <animated.div
                          style={{
                            ...(i === 0 && animate.animate
                              ? firstItemShakeAnimation
                              : '')
                          }}
                        >
                          {animate.animate && i === 0 && <AnimatedOverlay />}
                          <div className="top-bar-item">
                            <img
                              src={avatarUrl}
                              width="32px"
                              height="32px"
                              alt=""
                              onError={event => {
                                event.target.src = '/img/moonboy67.png'
                                event.onerror = null
                              }}
                            />
                            <Link
                              className="top-bar-address"
                              to={'/profile/?address=' + buyer}
                              target={
                                currentPath.includes('profile') ? '_blank' : ''
                              }
                              rel="noreferrer"
                            >
                              {buyer.slice(0, 2) + buyer.slice(-3)}
                            </Link>
                            <span className="top-bar-description">
                              {amount.toFixed(4)}  {' '}
                              {type === 'bought' ? 'AVAX' : 'Token'} {type} of{' '}
                            </span>
                            <Link
                              className="top-bar-address"
                              to={'/buy/?address=' + contract}
                              rel="noreferrer"
                            >
                              <span className="top-bar-token">{name}</span>
                              <img
                                src={imageUrl + contract + '-logo.png'}
                                width="32px"
                                height="32px"
                                alt=""
                                className="top-bar-token-icon"
                              />
                            </Link>
                            <br />
                          </div>
                        </animated.div>
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          className="top-bar"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <ChadHeaderLink className="tokenHeader" />
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
            className="nav-links"
          >
            <Link
              to="/AllLaunches"
              style={getLinkStyle('/AllLaunches')}
              className="top-bar-link"
              onMouseOver={e => {
                e.target.style.color =
                  currentPath === '/AllLaunches' ? 'white' : '#e8e6e3'
              }}
              onMouseOut={e => {
                e.target.style.color =
                  currentPath === '/AllLaunches'
                    ? 'white'
                    : 'rgb(168, 160, 149)'
              }}
            >
              Home
            </Link>
            <Link
              to="/CreateChad"
              style={getLinkStyle('/CreateChad')}
              className="top-bar-link"
              onMouseOver={e => {
                e.target.style.color =
                  currentPath === '/CreateChad' ? 'white' : '#e8e6e3'
              }}
              onMouseOut={e => {
                e.target.style.color =
                  currentPath === '/CreateChad' ? 'white' : 'rgb(168, 160, 149)'
              }}
            >
              Launch
            </Link>
            <Link
              to="/profile"
              style={getLinkStyle('/profile')}
              className="top-bar-link"
              onMouseOver={e => {
                e.target.style.color =
                  currentPath === '/profile' ? 'white' : '#e8e6e3'
              }}
              onMouseOut={e => {
                e.target.style.color =
                  currentPath === '/profile' ? 'white' : 'rgb(168, 160, 149)'
              }}
            >
              Profile
            </Link>
            <a
              href="https://t.me/ChadPumpFun"
              target="_blank"
              rel="noreferrer"
              style={externalLinkStyle}
              onMouseOver={e => {
                e.target.style.color = '#e8e6e3'
              }}
              onMouseOut={e => {
                e.target.style.color = 'rgb(168, 160, 149)'
              }}
              className="top-bar-link"
            >
              Telegram
            </a>
            <a
              href="https://x.com/ChadPumpFun"
              target="_blank"
              rel="noreferrer"
              style={externalLinkStyle}
              onMouseOver={e => {
                e.target.style.color = '#e8e6e3'
              }}
              onMouseOut={e => {
                e.target.style.color = 'rgb(168, 160, 149)'
              }}
              className="top-bar-link"
            >
              X.com
            </a>
          </div>
          <div className="navConnectButtonBox">
            {!isConnected ? (
              <button
                className="navConnectButton"
                type="submit"
                onClick={() => {
                  onConnectWallet()
                }}
              >
                Connect<span className="navWallet"> Wallet</span>
              </button>
            ) : (
              <section>
                <div className="ChainGroupButton">
                  {chain?.id === 43114 ? (
                    <button
                      className="navConnectButton"
                      type="submit"
                      onClick={() => onConnect()}
                    >
                      {address?.slice(0, 4) + '...' + address?.slice(-4)}
                    </button>
                  ) : (
                    <button
                      className="navConnectButton"
                      type="submit"
                      onClick={() => switchNetwork?.(43114)}
                    >
                      {'To Avax'}
                      {isLoading && pendingChainId === 43114 && ''}
                    </button>
                  )}
                </div>
              </section>
            )}
            <img
              src={iconHamburger}
              alt="Menu"
              className={`left-bar-menu ${isExpanded ? 'lg:hide-icon' : ''}`}
              onClick={handleHamburgerClick}
            />
          </div>

        </div>
      </div>
    </>
  )
}

export default TopBar
