/* eslint-disable no-useless-concat */
// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react'
import { useAccount } from 'wagmi'
import '../App.css'
import ChadFactoryAbi from '../config/ChadFactoryAbi.json'
import '../styles/MainContainer.css'
import Input from '../components/Input.tsx'
import TextArea from '../components/TextArea.tsx'
import { writeContract, readContract } from '@wagmi/core'
import ClipLoader from 'react-spinners/ClipLoader'
import { waitForTransaction } from '@wagmi/core'
import { useWeb3Modal } from '@web3modal/react'
import { toast } from 'react-hot-toast'
import Footer from '../components/Footer.jsx'
import 'react-datepicker/dist/react-datepicker.css'
import TopBar from '../components/TopBar.jsx'
import LogoUploadBox from '../components/LogoUploadBox.jsx'
import { factoryAddress, AvaxWeb3, imageUploadUrl } from '../utils/constants.ts'

const App = () => {
  const [logoPreview, setLogoPreview] = useState<string | null>(null)
  const [logoFile, setLogoFile] = useState<File | null>(null)
  const logoFileInput = useRef<HTMLInputElement>(null)
  const { isConnected } = useAccount()
  const [tokenName, setTokenName] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenDescription, setTokenDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const [creating, setCreating] = useState(false)
  const [website, setWebsite] = useState('')
  const [telegram, setTelegram] = useState('')
  const [discord, setDiscord] = useState('')
  const [twitter, setTwitter] = useState('')
  const [firstConnect, setFirstConnect] = useState(false)
  const { open } = useWeb3Modal()
  const [depositAmount, setDepositAmount] = useState('')
  const [receiveAmounts, setReceiveAmounts] = useState(0)
  const onConnectWallet = async () => {
    await open()
    setFirstConnect(true)
  }

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true) reloadWindow()
  }, [isConnected, firstConnect])

  useEffect(() => {
    if (loading === true) {
      setTimeout(function () {
        setLoading(false)
      }, 3000)
    }
  }, [loading])

  useEffect(() => {
    const FetchAmount = async () => {
      try {
        if (Number(depositAmount) > 0) {
          let avaxPrice = await readContract({
            address: factoryAddress,
            abi: ChadFactoryAbi,
            functionName: 'WavaxPrice'
          })
          let usdAmount = Number(depositAmount) * Number(avaxPrice) * 10 ** 6
          let price = (10 ** 15 * 530000 + usdAmount) / 10 ** 15
          let tokenAmount = (Number(depositAmount) * Number(avaxPrice)) / price
          setReceiveAmounts(tokenAmount.toFixed(2))
        } else {
          setReceiveAmounts(0)
        }
      } catch (e) {
      }
    }

    FetchAmount()

  }, [depositAmount])

  const onChadPumpCreate = async () => {
    try {
      setCreating(true)
      let feeAmount = 0.1
      if (logoFile) {
        let create
        create = await writeContract({
          address: factoryAddress,
          abi: ChadFactoryAbi,
          functionName: 'createChadPump',
          value: AvaxWeb3.utils.toWei(
            String(feeAmount + Number(depositAmount)),
            'ether'
          ),
          args: [
            [
              tokenName,
              tokenSymbol,
              tokenDescription,
              website,
              twitter,
              telegram,
              discord
            ]
          ]
        })
        await waitForTransaction({
          hash: create.hash
        })
        let funAddresses
        funAddresses = await readContract({
          address: factoryAddress,
          abi: ChadFactoryAbi,
          functionName: 'getAllAddresses'
        })
        let presaleAddress
        if (funAddresses) presaleAddress = funAddresses[funAddresses.length - 1]
        let logoUrl
        const formData = new FormData()
        formData.append('file', logoFile, presaleAddress)
        fetch(imageUploadUrl + 'logoUploads', {
          method: 'POST',
          body: formData
        })
          .then(async res => {
            logoUrl = await res.json()
            logoUrl = logoUrl.fileInfo.filename
            toast.success(`Successfully ${tokenName} Chad Pump created`)
            const link = `/buy/?address=${presaleAddress}`
            window.location.href = link
          })
          .catch(error => {
            setCreating(false)
            console.error('Error:', error)
          })
        setCreating(false)
      } else {
        setCreating(false)
        toast.error('please upload correct image file')
      }
    } catch (err) {
      setCreating(false)
      toast.error(
        'There is a problem with your Chad Pump create. Please try again later'
      )
    }
  }

  const [, setImageLogoFile] = useState(null)

  const handleImageLogoChange = file => {
    setImageLogoFile(file)
  }

  const LogoImageUpload = ({ onChange, className, style }) => {
    const handleLogoImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = e.target.files![0]
      setLogoFile(selectedFile)
      setLogoPreview(URL.createObjectURL(selectedFile))
      onChange(selectedFile)
    }
    const onButtonClick = () => {
      if (logoFileInput.current) {
        logoFileInput.current.click()
      }
    }
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <input
          type="file"
          ref={logoFileInput}
          accept="image/*"
          onChange={handleLogoImageChange}
          style={{ display: 'none' }}
        />
        <LogoUploadBox
          imageUrl={logoPreview}
          handleClick={onButtonClick}
          className={className}
          style={style}
        />
      </div>
    )
  }

  return (
    <div>
      <div className="GlobalContainer">
        <div style={{ zIndex: 1 }}>
          <TopBar />
          <div className="navBar"></div>
          <div className="headerMargin" />
          <div className="MainDashboard ResponsiveFlexLayout">
            <section className="RightColumn">
              <section>
                <section className="DepositBoxHeader">
                  <p className="ContractContentTextTitle h1">
                    Launch Your Token on ChadPump
                  </p>
                </section>
                <div className="MainContainerChadPump">
                  <div>
                    <section>
                      <div className="LpBalance">
                        <p className="Text1">
                          Image
                          <span style={{ color: '#b482ff' }}>*</span>
                        </p>
                      </div>
                      <section className="inputPanel">
                        <section className="inputPanelHeader">
                          <LogoImageUpload
                            onChange={handleImageLogoChange}
                            className={undefined}
                            style={undefined}
                          />
                        </section>
                      </section>
                    </section>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1
                      }}
                    >
                      <section
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: '16px',
                          position: 'relative'
                        }}
                      >
                        <div className="LpBalance">
                          <p className="Text1">
                            Token Name
                            <span style={{ color: '#b482ff' }}>*</span>
                            <span
                              style={{
                                color: 'rgba(232, 230, 227, 0.7)',
                                fontWeight: 600,
                                fontFamily: 'PingFang SC, sans-serif',
                                position: 'absolute',
                                right: '8px',
                                top: '8px'
                              }}
                            >
                              {tokenName.length}/20
                            </span>
                          </p>
                        </div>
                        <section className="inputPanel">
                          <section className="inputPanelHeader">
                            <Input
                              style={{ width: '100%' }}
                              placeholder="Enter Name"
                              label=""
                              type="text"
                              onChange={e => setTokenName(e.target.value)}
                              value={tokenName}
                              maxLength={20}
                            />
                          </section>
                        </section>
                      </section>

                      <section
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'relative'
                        }}
                      >
                        <div className="LpBalance">
                          <p className="Text1">
                            Token Symbol ($Ticker)
                            <span style={{ color: '#b482ff' }}>*</span>
                            <span
                              style={{
                                color: 'rgba(232, 230, 227, 0.7)',
                                fontWeight: 600,
                                fontFamily: 'PingFang SC, sans-serif',
                                position: 'absolute',
                                right: '8px',
                                top: '8px'
                              }}
                            >
                              {tokenSymbol.length}/10
                            </span>
                          </p>
                        </div>
                        <section className="inputPanel">
                          <section className="inputPanelHeader">
                            <Input
                              style={{ width: '100%' }}
                              placeholder="Enter Symbol"
                              label=""
                              type="text"
                              onChange={e => setTokenSymbol(e.target.value)}
                              value={tokenSymbol}
                              maxLength={10}
                            />
                          </section>
                        </section>
                      </section>
                    </div>
                  </div>

                  <section className="InputBox">
                    <section className="InputSection_Description">
                      <div className="LpBalance" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p className="Text1">
                          Token Description
                          <span style={{ color: '#b482ff' }}>*</span>
                        </p>
                        <span
                          style={{
                            color: 'rgba(232, 230, 227, 0.7)',
                            fontWeight: 600,
                            fontFamily: 'PingFang SC, sans-serif',
                          }}
                        >
                          {tokenDescription.length}/256
                        </span>
                      </div>
                      <section className="inputPanel">
                        <section className="inputPanelHeader">
                          <TextArea
                            rows={6}
                            placeholder="Enter Token Description"
                            onChange={e => setTokenDescription(e.target.value)}
                            value={tokenDescription}
                            maxLength={256}
                          />
                        </section>
                      </section>
                    </section>
                  </section>


                  <section className="InputBox">
                    <section className="InputSection_Description">
                      <div className="LpBalance">
                        <p className="Text1">Website</p>
                      </div>
                      <section className="inputPanel">
                        <section className="inputPanelHeader">
                          <Input
                            placeholder="Optional"
                            label=""
                            type="text"
                            onChange={e => setWebsite(e.target.value)}
                            value={website}
                          />
                        </section>
                      </section>
                    </section>
                  </section>

                  <section className="InputBox">
                    <section className="InputSection_Description">
                      <div className="LpBalance">
                        <p className="Text1">Telegram</p>
                      </div>
                      <section className="inputPanel">
                        <section className="inputPanelHeader">
                          <Input
                            placeholder="Optional"
                            label=""
                            type="text"
                            onChange={e => setTelegram(e.target.value)}
                            value={telegram}
                          />
                        </section>
                      </section>
                    </section>
                  </section>
                  <section className="InputBox">
                    <section className="InputSection_Description">
                      <div className="LpBalance">
                        <p className="Text1">Twitter</p>
                      </div>
                      <section className="inputPanel">
                        <section className="inputPanelHeader">
                          <Input
                            placeholder="Optional"
                            label=""
                            type="text"
                            onChange={e => setTwitter(e.target.value)}
                            value={twitter}
                          />
                        </section>
                      </section>
                    </section>
                  </section>

                  <section className="InputBox">
                    <section className="InputSection_Description">
                      <div className="LpBalance">
                        <p className="Text1">
                          Initial Buy
                          <span className="text-gray">
                            &nbsp;be the first person to buy your token
                          </span>
                        </p>
                      </div>
                      <section className="inputPanel">
                        <section className="inputPanelHeader">
                          <Input
                            placeholder="(Optional) Enter the amount of AVAX you want to spend"
                            label=""
                            type="number"
                            onChange={e => setDepositAmount(e.target.value)}
                            value={depositAmount}
                          />
                        </section>
                      </section>
                      {receiveAmounts > 0 && (
                        <p>You will get {receiveAmounts} tokens.</p>
                      )}
                    </section>
                  </section>
                  <div style={{ marginBottom: '48px' }} />
                  <div
                    className='CreateButtonBox'
                  >
                    {creating === false ? (
                      isConnected ? (
                        <button
                          disabled={
                            tokenName === '' ||
                            tokenSymbol === '' ||
                            tokenDescription === '' ||
                            logoFile === null
                          }
                          onClick={onChadPumpCreate}
                          className="CreateButton"
                        >
                          {tokenName === '' ||
                            tokenSymbol === '' ||
                            tokenDescription === '' ||
                            logoFile === null
                            ? 'Please Enter Chad Details'
                            : 'Create Token'}
                        </button>
                      ) : (
                        <button
                          className="CreateButton"
                          type="submit"
                          onClick={() => {
                            onConnectWallet()
                          }}
                        >
                          Connect
                          <span className="navWallet"> Wallet</span>
                        </button>
                      )
                    ) : (
                      <div className="loadingBox">
                        <p className="Text1">Creating...</p>
                        <ClipLoader
                          color={'#afccc6'}
                          loading={creating}
                          size={30}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default App
