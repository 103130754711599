/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-useless-concat */
// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react'
import { useAccount, useNetwork } from 'wagmi'
import '../App.css'
import MultiCallAbi from '../config/MultiCallAbi.json'
import '../styles/MainContainer.css'
import Footer from '../components/Footer.jsx'
import copyIcon from '../icons/copy.svg'
import TopBar from '../components/TopBar.jsx'
import CustomRadioButton from '../components/CustomRadioButton.jsx'
import { StringParam, useQueryParam } from 'use-query-params'
import BalanceCard from '../components/BalanceCard.jsx'
import LaunchpadCard from '../components/LaunchpadCard.jsx'
import {
  multicallAddress,
  publicClient,
  imageUrl,
  apiUrl
} from '../utils/constants.ts'
import { Link } from 'react-router-dom'
import ProfileUploadBox from '../components/ProfileUploadBox.jsx'
import ClipLoader from 'react-spinners/ClipLoader'
import { ReactComponent as EditIcon } from '../icons/edit.svg'
import { toast } from 'react-hot-toast'
import { imageUploadUrl } from '../utils/constants.ts'

const Profile = () => {
  let [profileAddress] = useQueryParam('address', StringParam)
  const { address } = useAccount()
  const { chain } = useNetwork()
  if (!profileAddress) profileAddress = address
  const [userName, setUserName] = useState('@moonboy67')
  const [userTelegram, setUserTelegram] = useState('')
  const [userX, setUserX] = useState('')
  const [userWebsite, setUserWebsite] = useState('')
  const logoFileInput = useRef<HTMLInputElement>(null)
  let [loading, setLoading] = useState(false)
  const [userAvatarUrl, setUserAvatarUrl] = useState('/img/moonboy67.png')
  const [isCopied, setIsCopied] = useState(false)

  const [selectedOption, setSelectedOption] = useState('Token Owned')
  const handleSelect = value => setSelectedOption(value)
  const [, setTotalLength] = useState(0)
  const [logoPreview, setLogoPreview] = useState<string | null>()
  const [logoFile, setLogoFile] = useState<File | null>(null)
  const [chadLists, setChadListData] = useState([
    {
      progress: 0,
      Liquidity: 0,
      tokenName: '',
      logoUrl: '',
      address: '',
      depositedAmount: 0,
      contractAddress: '',
      dexAddress: '',
      devAddress: '',
      dexName: '',
      marketCap: '',
      website: '',
      twitter: '',
      telegram: '',
      blockchainLogoUrl: ''
    }
  ])
  const [chadBalances, setChadBalances] = useState([
    { name: '', symbol: '', tokenAddress: '', logoUrl: '', balance: 0 }
  ])
  const [, setHistory] = useState([
    { amount: 0, name: '', token: '', type: '', date: '' }
  ])

  const copyToClipboard = async text => {
    try {
      await navigator.clipboard.writeText(text)
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    } catch (err) {
      console.error('Failed to copy text: ', err)
    }
  }

  const CopiedPopup = ({ isVisible }) =>
    isVisible && <div className="copied-popup">Copied!</div>

  const handleEditIconClick = () => {
    setSelectedOption('Details')
  }

  useEffect(() => {
    const FetchDeployedData = async () => {
      try {
        setLoading(true)
        let mainInfo = await publicClient.readContract({
          address: multicallAddress,
          abi: MultiCallAbi,
          functionName: 'getUserDeployMainInfo',
          args: [profileAddress]
        })
        let otherInfo = await publicClient.readContract({
          address: multicallAddress,
          abi: MultiCallAbi,
          functionName: 'getUserDeployOtherInfo',
          args: [profileAddress]
        })
        let chadListsData: any[] = []
        let chadData = {}

        if (mainInfo && otherInfo) {
          setTotalLength(mainInfo[0].length)
          if (mainInfo[0].length > 0) {
            for (let i = mainInfo[0].length - 1; i >= 0; i--) {
              let progress
              const contractAddress = mainInfo[5][i]
              const virtualLpAmounts = Number(mainInfo[2][i])
              const virtualLpTokenAmounts = Number(mainInfo[1][i]) / 10 ** 18
              const tokenPrice = Number(mainInfo[3][i])
              const marketCap = (tokenPrice * 1000000000) / 10 ** 12
              const website = otherInfo[2][i]
              const twitter = otherInfo[3][i]
              const telegram = otherInfo[4][i]
              progress =
                ((800000000 - (Number(virtualLpTokenAmounts) - 200000000)) *
                  100) /
                800000000
              const liquidity = virtualLpAmounts
              const name = otherInfo[0][i]
              let logoUrl = imageUrl + contractAddress + '-logo.png'
              let blockchainLogoUrl = '/blockchain.png'
              let devAddress = mainInfo[6][i]
              let dexAddress = 'https://traderjoexyz.com/avalanche/trade'
              let dexName = 'Trader Joe'
              let bannerUrl = imageUrl + contractAddress + '-banner.png'
              let tokenPriceChange = ((tokenPrice - 530000) / (6900000 - 530000)) * 100;
              tokenPriceChange = Number(tokenPriceChange.toFixed(3))
              const symbol = otherInfo[1][i]
              const info = otherInfo[6][i]
              chadData = {
                progress: progress,
                Liquidity: liquidity,
                tokenName: name,
                logoUrl: logoUrl,
                bannerUrl: bannerUrl,
                address: mainInfo[5][i],
                depositedAmount: Number(mainInfo[4][i]) / 10 ** 18,
                contractAddress: contractAddress,
                dexAddress: dexAddress,
                devAddress: devAddress,
                dexName: dexName,
                marketCap: marketCap,
                website: website,
                twitter: twitter,
                telegram: telegram,
                blockchainLogoUrl: blockchainLogoUrl,
                tokenPriceChange: tokenPriceChange,
                symbol: symbol,
                info: info
              }
              if (chadData) chadListsData.push(chadData)
            }
          }
        }
        setChadListData(chadListsData)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.error(e)
      }
    }
    const FetchBalances = async () => {
      try {
        setLoading(true)
        let mainInfo = await publicClient.readContract({
          address: multicallAddress,
          abi: MultiCallAbi,
          functionName: 'getUserBalance',
          args: [profileAddress]
        })
        let chadListsData: any[] = []
        let chadData = {}
        if (mainInfo) {
          setTotalLength(mainInfo[0].length)
          if (mainInfo[0].length > 0) {
            for (let i = mainInfo[0].length - 1; i >= 0; i--) {
              const name = mainInfo[0][i]
              const symbol = mainInfo[1][i]
              const tokenAddress = mainInfo[2][i]
              const balance = Number(mainInfo[3][i]) / 10 ** 18
              let logoUrl = imageUrl + tokenAddress + '-logo.png'
              chadData = {
                name: name,
                symbol: symbol,
                tokenAddress: tokenAddress,
                logoUrl: logoUrl,
                balance: balance
              }
              if (chadData) chadListsData.push(chadData)
            }
          }
        }
        setChadBalances(chadListsData)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.error(e)
      }
    }

    const FetchTrades = async () => {
      try {
        await fetch(apiUrl + `/api/getUserHistory/${profileAddress}`, {
          method: 'GET'
        }).then(async res => {
          let data = await res.json()
          if (data.length > 0) {
            let history = {}
            let historyData: any[] = []
            for (let i = 0; i < data?.length; i++) {
              let amount = data[i].amount
              let buyer = data[i].buyer
              let name = data[i].name
              let token = data[i].token
              let type = data[i].type
              let contract = data[i].contract
              let currentDate = Date.now()
              let timestamp = currentDate / 1000 - Number(data[i].timestamp)
              let date
              if (timestamp > 86400) {
                date = (timestamp / 86400).toFixed(0) + ' days ago'
              } else if (timestamp > 3600) {
                date = (timestamp / 3600).toFixed(0) + ' hours ago'
              } else if (timestamp > 0) {
                date = (timestamp / 60).toFixed(0) + ' mins ago'
              } else {
                date = ' just now'
              }
              history = {
                amount: amount,
                buyer: buyer,
                name: name,
                token: token,
                type: type,
                contract: contract,
                date: date
              }
              historyData.push(history)
            }
            setHistory(historyData)
          } else {
            setHistory([{ amount: 0, name: '', token: '', type: '', date: '' }])
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
    if (selectedOption === 'Token Created') {
      FetchDeployedData()
    } else if (selectedOption === 'Token Owned') {
      FetchBalances()
    } else if (selectedOption === 'Trades') {
      FetchTrades()
    }
  }, [chain?.id, selectedOption, profileAddress])

  useEffect(() => {
    const FetchInfo = async () => {
      try {
        setUserAvatarUrl(imageUrl + `profile-${profileAddress}.png`)
        await fetch(apiUrl + `/api/getProfile/${profileAddress}`, {
          method: 'GET'
        }).then(async res => {
          let data = await res.json()
          if (data.length > 0) {
            setUserName(data[0].name)
            setUserTelegram(data[0].telegram)
            setUserX(data[0].twitter)
            setUserWebsite(data[0].website)
          } else {
            setUserName('@mooning')
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
    if (profileAddress) {
      FetchInfo()
    }
  }, [profileAddress, address])


  const truncateAddress = address => {
    if (address) {
      return `${address.substring(0, 5)}...${address.substring(
        address.length - 3
      )}`
    } else {
      return ''
    }
  }


  useEffect(() => {
    const FetchInfo = async () => {
      try {
        setUserAvatarUrl(imageUrl + `profile-${profileAddress}.png`)
        await fetch(apiUrl + `/api/getProfile/${profileAddress}`, {
          method: 'GET'
        })
          .then(async res => {
            let data = await res.json()
            if (data.length > 0) {
              setUserName(data[0].name)
              setUserTelegram(data[0].telegram)
              setUserX(data[0].twitter)
              setUserWebsite(data[0].website)
            } else {
              setUserName('@mooning')
            }
          })
      } catch (e) {
        console.error(e)
      }
    }
    if (profileAddress) {
      FetchInfo()
    }
  }, [profileAddress, address])

  const LogoImageUpload = ({ onChange, className, style }) => {
    const handleLogoImageChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const selectedFile = e.target.files![0]
      setLogoFile(selectedFile)
      setLogoPreview(URL.createObjectURL(selectedFile))
      onChange(selectedFile)
    }
    const onButtonClick = () => {
      if (logoFileInput.current) {
        logoFileInput.current.click()
      }
    }
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <input
          type="file"
          ref={logoFileInput}
          accept="image/*"
          onChange={handleLogoImageChange}
          style={{ display: 'none' }}
        />
        <ProfileUploadBox
          imageUrl={logoPreview}
          handleClick={onButtonClick}
          className={className}
          style={style}
        />
      </div>
    )
  }

  const [, setImageLogoFile] = useState(null)
  const handleImageLogoChange = file => {
    setImageLogoFile(file)
  }

  const onSave = async () => {
    try {
      setLoading(true)
      let logoUrl
      if (logoFile) {
        const formData = new FormData()
        formData.append('file', logoFile, address)
        fetch(imageUploadUrl + 'profileUploads', {
          method: 'POST',
          body: formData
        })
          .then(async res => {
            logoUrl = await res.json()
            logoUrl = logoUrl.fileInfo.filename
          })
          .catch(error => {
            setLoading(false)
            console.error('Error:', error)
          })
      }
      const currentTime = (Date.now() / 1000).toFixed(0);
      const sendData = {
        profileAddress: address,
        name: userName,
        telegram: userTelegram,
        twitter: userX,
        website: userWebsite,
        timestamp: currentTime
      }
      const response = await fetch(apiUrl + '/api/addprofile', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'error',
        body: JSON.stringify(sendData)
      })

      if (response.status !== 200) {
        const { error } = await response.json()
        throw new Error(error)
      }
      toast.success(`Updated`)
      setLoading(false)


    } catch (err) {
      setLoading(false)
      toast.error(
        'There is a problem with your update. Try again later'
      )
    }
  }

  return (
    <div>
      <div className="GlobalContainer">
        <div style={{ zIndex: 1 }}>
          <TopBar />
          <div className="headerMargin" />
          <div className="profile-card-header">
            <Link
              className="back-button"
              to="/"
            >
              &lt; Back Home
            </Link>
          </div>
          <div
            className="profile-card"
            style={{
              backgroundSize: 'auto 192px',
              backgroundPosition: 'center top',
              backgroundRepeat: 'no-repeat',
              overflowX: 'hidden',
              position: 'relative'
            }}
          >
            <div
              className="launchpad-eth-logo-container relative"
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <img
                  src={userAvatarUrl}
                  className="profile-avatar"
                  onError={event => {
                    event.target.src = '/img/moonboy67.png'
                    event.onerror = null
                  }}
                  alt=""
                  style={{
                    width: '120px',
                    height: '120px',
                    marginRight: '20px'
                  }}
                />
                <div>
                  <p
                    className="profile-name"
                    style={{
                      fontSize: '32px',
                      color: 'white',
                      fontWeight: 'bold'
                    }}
                  >
                    {userName}
                    {profileAddress === address && address && (
                      <button
                        onClick={handleEditIconClick}
                        style={{ all: 'unset', cursor: 'pointer' }}
                      >
                        <EditIcon className="profile-edit" />
                      </button>
                    )}
                  </p>
                  {profileAddress && (
                    <p
                      className="profile-address"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '20px',
                        color: '#92959c',
                        marginTop: '10px'
                      }}
                      onClick={() => copyToClipboard(profileAddress)}
                    >
                      {truncateAddress(profileAddress)}{' '}
                      <img
                        src={copyIcon}
                        alt="Copy"
                        style={{
                          width: '24px',
                          height: '24px',
                          marginLeft: '8px',
                          cursor: 'pointer'
                        }}
                        onClick={() => copyToClipboard(profileAddress)}
                      />
                      <CopiedPopup isVisible={isCopied} />
                    </p>
                  )}

                </div>
              </div>
            </div>

            <br />
          </div>

          <section className="ProfileBox">
            <div className="custom-radio-button-wrapper">
              <CustomRadioButton
                value="Token Owned"
                selectedValue={selectedOption}
                handleSelect={handleSelect}
              />
              <CustomRadioButton
                value="Token Created"
                selectedValue={selectedOption}
                handleSelect={handleSelect}
              />
            </div>
            <div
              style={{
                maxWidth: '100%',
                width: '840px',
                opacity: '0.5',
                border: 'dashed 1px #777',
                margin: 'auto',
                marginBottom: '20px'
              }}
            />
            {selectedOption === 'Token Owned' ? (
              <>
                {chadBalances[0]?.name !== '' ? (
                  chadBalances.map(
                    ({ name, symbol, tokenAddress, logoUrl, balance }, i) => (
                      <BalanceCard
                        key={i}
                        name={name}
                        symbol={symbol}
                        tokenAddress={tokenAddress}
                        Logo={
                          <img
                            src={logoUrl}
                            className="balance-token-logo"
                            alt=""
                          />
                        }
                        balance={balance}
                      />
                    )
                  )
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {selectedOption === 'Token Created' ? (
              <div className="launchpad-card-grid">
                {chadLists.map(
                  (
                    {
                      progress,
                      Liquidity,
                      tokenName,
                      logoUrl,
                      address,
                      depositedAmount,
                      contractAddress,
                      dexAddress,
                      devAddress,
                      dexName,
                      marketCap,
                      website,
                      twitter,
                      telegram,
                      blockchainLogoUrl,
                      tokenPriceChange,
                      symbol,
                      info
                    },
                    i
                  ) =>
                    tokenName !== '' ? (
                      <LaunchpadCard
                        progress={progress}
                        Liquidity={Liquidity}
                        tokenName={tokenName}
                        Logo={
                          <img src={logoUrl} className="claim-card-logo" />
                        }
                        Banner={logoUrl}
                        chadAddress={address}
                        depositedAmount={depositedAmount}
                        contractAddress={contractAddress}
                        devAddress={devAddress}
                        devName={symbol}
                        changePercentage={tokenPriceChange}
                        description={info}
                        marketCap={marketCap}
                        website={website}
                        twitter={twitter}
                        telegram={telegram}
                        BlockchainLogo={
                          <img
                            src={blockchainLogoUrl}
                            className="launchpad-blockchain-logo"
                          />
                        }
                      />
                    ) : (
                      <></>
                    )
                )}
              </div>
            ) : (
              <></>
            )}
            {selectedOption === 'Details' && address === profileAddress && (
              <>
                <br />
                <div className="profile-card">
                  <div className="user-details-wrapper">
                    <div className="profile-title">
                      <p>Logo</p>
                    </div>
                    <div className="custom-file-input-container">
                      <div className="profile-user-avatar">
                        <LogoImageUpload
                          onChange={handleImageLogoChange}
                          className="upload-box-profile-banner"
                          style={undefined}
                        />
                      </div>
                    </div>

                    <div className="profile-title">
                      <p>Username</p>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={userName}
                        className="profile-text-input"
                        onChange={e => setUserName(e.target.value)}
                      />
                    </div>

                    <div className="profile-title">
                      <p>Telegram</p>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={userTelegram}
                        className="profile-text-input"
                        onChange={e => setUserTelegram(e.target.value)}
                      />
                    </div>

                    <div className="profile-title">
                      <p>X.com Link</p>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={userX}
                        className="profile-text-input"
                        onChange={e => setUserX(e.target.value)}
                      />
                    </div>

                    <div className="profile-title">
                      <p>Website Link</p>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={userWebsite}
                        className="profile-text-input"
                        onChange={e => setUserWebsite(e.target.value)}
                      />
                    </div>
                  </div>
                  <br />
                  <div style={{ textAlign: 'center' }}>
                    {loading === false ?
                      <button className="save-button" onClick={onSave}>Save</button>
                      :
                      <>
                        <div className="loadingBox">
                          <p className="Text1">
                            Loading...
                          </p>
                          <ClipLoader
                            color={'#afccc6'}
                            loading={loading}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      </>
                    }
                  </div>
                </div>
                <br />
              </>
            )}
          </section>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Profile
