/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import { useState, useEffect, useCallback, useRef } from 'react'
import { useNetwork } from 'wagmi'
import '../App.css'
import MultiCallAbi from '../config/MultiCallAbi.json'
import '../styles/MainContainer.css'
import LaunchpadCard from '../components/LaunchpadCard'
import ClipLoader from 'react-spinners/ClipLoader'
import Footer from '../components/Footer'
import TopBar from '../components/TopBar'
import Select from 'react-select'
import { useSpring, animated } from 'react-spring'
import Cookies from 'universal-cookie'
import {
  multicallAddress,
  publicClient,
  imageUrl,
  defaultAddress
} from '../utils/constants.ts'
import sortAscendingIcon from '../icons/sort-ascending.svg'
import sortDescendingIcon from '../icons/sort-descending.svg'
import searchIcon from '../icons/search.svg'
import TopPanel from '../components/TopPanel'

const AllLaunches = () => {
  const { chain } = useNetwork()
  const [loading, setLoading] = useState(false)
  const [chadLists, setChadListData] = useState([])
  const [filteredChadLists, setFilteredChadLists] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const cookies = new Cookies()
  const [modalIsOpen, setModalIsOpen] = useState(true)
  const [filterOption, setFilterOption] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const [sortedChadLists, setSortedChadLists] = useState([])
  const [sortValue, setSortValue] = useState('Creating Date')
  const [orderValue, setOrderValue] = useState('Descending')
  const statusOptions = [
    { value: 'All', label: 'All' },
    { value: 'Listed', label: 'Listed' },
    { value: 'Live', label: 'Live' }
  ]
  const [statusValue, setStatusValue] = useState(statusOptions[0])

  const sortOptions = [
    { value: 'Market Cap', label: 'Market Cap' },
    { value: 'Creating Date', label: 'Creating Date' },
    // { value: 'Freshly Aped', label: 'Freshly Aped' },
    // { value: 'Messages', label: 'Messages' },
    // { value: 'Volume', label: 'Volume' }
  ]

  const [totalLength, setTotalLength] = useState(0)
  const [allLists, setAllLists] = useState([])
  const fetchData = async () => {
    try {
      setLoading(true)

      if (cookies.get('show-how-it-works')) {
        setModalIsOpen(false)
      }

      const mainInfo = await publicClient.readContract({
        address: multicallAddress,
        abi: MultiCallAbi,
        functionName: 'getMainInfo'
      })
      const otherInfo = await publicClient.readContract({
        address: multicallAddress,
        abi: MultiCallAbi,
        functionName: 'getOtherInfo'
      })
      let chadListsData = []
      let chadData
      if (mainInfo[0].length > 0) {
        for (let i = mainInfo[0].length - 1; i >= 0; i--) {
          let progress
          const createdTime = mainInfo[0][i]
          const contractAddress = mainInfo[5][i]
          const virtualLpAmounts = Number(mainInfo[2][i])
          const virtualLpTokenAmounts = Number(mainInfo[1][i]) / 10 ** 18
          const tokenPrice = Number(mainInfo[3][i])
          const marketCap = (tokenPrice * 1000000000) / 10 ** 12
          let tokenPriceChange = ((tokenPrice - 530000) / (6900000 - 530000)) * 100;
          tokenPriceChange = tokenPriceChange.toFixed(3)
          const website = otherInfo[2][i]
          const twitter = otherInfo[3][i]
          const telegram = otherInfo[4][i]
          progress =
            ((800000000 - (Number(virtualLpTokenAmounts) - 200000000)) * 100) /
            800000000
          const liquidity = virtualLpAmounts
          const name = otherInfo[0][i]
          const symbol = otherInfo[1][i]
          const info = otherInfo[6][i]
          let logoUrl = imageUrl + contractAddress + '-logo.png'
          let bannerUrl = imageUrl + contractAddress + '-banner.png'
          let blockchainLogoUrl = '/blockchain.png'
          let devAddress = mainInfo[6][i]
          let dexAddress = 'https://traderjoexyz.com/avalanche/trade'
          let dexName = 'Trader Joe'
          chadData = {
            progress: progress,
            Liquidity: liquidity,
            tokenName: name,
            logoUrl: logoUrl,
            bannerUrl: bannerUrl,
            address: mainInfo[5][i],
            depositedAmount: Number(mainInfo[4][i]) / 10 ** 18,
            contractAddress: contractAddress,
            dexAddress: dexAddress,
            devAddress: devAddress,
            dexName: dexName,
            marketCap: marketCap,
            website: website,
            twitter: twitter,
            telegram: telegram,
            blockchainLogoUrl: blockchainLogoUrl,
            tokenPriceChange: tokenPriceChange,
            symbol: symbol,
            info: info,
            createdTime: createdTime
          }
          if (filterOption === 1 && otherInfo[4][i] === defaultAddress) {
            chadListsData.push(chadData)
          } else if (filterOption === 2 && otherInfo[4][i] !== defaultAddress) {
            chadListsData.push(chadData)
          } else if (filterOption === 0) {
            chadListsData.push(chadData)
          }
        }
      }
      setChadListData(chadListsData)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [chain?.id, filterOption])

  const filterChadLists = useCallback(() => {
    let filteredList = []
    switch (statusValue.value) {
      case 'All':
        filteredList = [...chadLists]
        setFilterOption(0)
        break
      case 'Listed':
        filteredList = [...chadLists] // TODO: Add filtering logic for "Listed" condition
        setFilterOption(1)
        break
      case 'Live':
        filteredList = [...chadLists] // TODO: Add filtering logic for "Live" condition
        setFilterOption(2)
        break
      default:
        break
    }
    setFilteredChadLists(filteredList)
  }, [statusValue, chadLists])


  const handleAnimateChange = e => {
    const newValue = e.target.checked
    setAnimate(newValue)
  }

  const sortChadLists = useCallback(() => {
    let sortedList = []
    switch (sortValue) {
      case 'Market Cap':
        sortedList = [...filteredChadLists].sort((a, b) => {
          if (orderValue === 'Ascending') {
            return a.marketCap - b.marketCap
          } else {
            return b.marketCap - a.marketCap
          }
        })
        break
      case 'Creating Date':
        sortedList = [...filteredChadLists].sort((a, b) => {
          if (orderValue === 'Ascending') {
            // TODO: Add sorting logic for ascending order
            return Number(a.createdTime) - Number(b.createdTime)
          } else {
            // TODO: Add sorting logic for descending order
            return Number(b.createdTime) - Number(a.createdTime)
          }
        })
        break
      // case 'Freshly Aped':
      //   sortedList = [...filteredChadLists].sort((a, b) => {
      //     if (orderValue === 'Ascending') {
      //       // TODO: Add sorting logic for ascending order
      //       return 0
      //     } else {
      //       // TODO: Add sorting logic for descending order
      //       return 0
      //     }
      //   })
      //   break
      // case 'Messages':
      //   sortedList = [...filteredChadLists].sort((a, b) => {
      //     if (orderValue === 'Ascending') {
      //       // TODO: Add sorting logic for ascending order
      //       return 0
      //     } else {
      //       // TODO: Add sorting logic for descending order
      //       return 0
      //     }
      //   })
      //   break
      // case 'Volume':
      //   sortedList = [...filteredChadLists].sort((a, b) => {
      //     console.log('debug', a, b)
      //     if (orderValue === 'Ascending') {
      //       // TODO: Add sorting logic for ascending order
      //       return 0
      //     } else {
      //       // TODO: Add sorting logic for descending order
      //       return 0
      //     }
      //   })
      // break
      default:
        break
    }
    setAllLists(sortedList)
    let last
    let length = sortedList.length;
    setTotalLength(length);
    let datas = [];
    if (length > 6) {
      last = 6
    } else {
      last = length
    }
    for (let i = 0; i < last; i++) {
      datas.push(sortedList[i])
    }
    setCurrentIndex(last)

    setSortedChadLists(datas)
  }, [orderValue, sortValue, filteredChadLists])

  useEffect(() => {
    setSortedChadLists([...filteredChadLists])
  }, [filteredChadLists, orderValue, sortValue])

  useEffect(() => {
    setFilteredChadLists([...chadLists])
  }, [chadLists, statusValue])

  useEffect(() => {
    filterChadLists()
  }, [statusValue, filterChadLists])

  useEffect(() => {
    sortChadLists()
  }, [orderValue, sortValue, filteredChadLists, sortChadLists])

  const onSortChange = newValue => {
    setSortValue(newValue.value)
    sortChadLists()
  }

  const onStatusChange = newValue => {
    setStatusValue(newValue)
    filterChadLists()
  }

  const toggleSortOrder = () => {
    if (orderValue === 'Ascending') {
      setOrderValue('Descending')
    } else {
      setOrderValue('Ascending')
    }
    sortChadLists()
  }

  const [animate, setAnimate] = useState(true)
  const prevAnimateRef = useRef(animate)
  const [animatedChadLists, setAnimatedChadLists] = useState([])

  useEffect(() => {
    if (animate === prevAnimateRef.current) {
      if (animate) {
        setAnimatedChadLists(sortedChadLists)
      } else {
        sortChadLists()
      }
      prevAnimateRef.current = animate
    }
  }, [animate, sortChadLists, sortedChadLists])

  const animateList = useCallback(() => {

    if (animate && sortedChadLists.length > 0 && animatedChadLists.length > 0) {
      // Shift the list items by one
      const itemToMove = animatedChadLists.shift()

      animatedChadLists.push(itemToMove)
      setAnimatedChadLists([...animatedChadLists])
    }
  }, [animate, sortedChadLists, animatedChadLists])

  useEffect(() => {
    let intervalId = null
    if (animate) {
      intervalId = setInterval(() => {
        animateList()
      }, 3000)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [animate, animateList])

  const overlayOpacity = useSpring({
    from: { opacity: 0 },
    to: [{ opacity: 0.8 }, { opacity: 0.6 }, { opacity: 0.2 }, { opacity: 0 }],
    config: {
      duration: 100,
      easing: t => t * (2 - t)
    }
  })

  const AnimatedOverlay = () => (
    <animated.div
      style={{
        ...overlayOpacity
      }}
    />
  )

  const LaunchpadCardGrid = ({ items, animate, key }) => {
    const firstItemShakeAnimation = useSpring({
      from: { transform: 'translateX(0px)' },
      to: [
        { transform: 'translateX(-20px)' },
        { transform: 'translateX(15px)' },
        { transform: 'translateX(-10px)' },
        { transform: 'translateX(5px)' },
        { transform: 'translateX(0px)' }
      ],
      config: {
        duration: 50, // Adjust duration as needed
        easing: t => t * (2 - t) // Easing function for a smooth start and end
      }
    })

    return (
      <div className="launchpad-card-grid" key={key}>
        {items
          .filter(
            item =>
              item.tokenName.toLowerCase().includes(searchTerm.toLowerCase()) ||
              item.address.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map(
            (
              {
                progress,
                Liquidity,
                tokenName,
                logoUrl,
                bannerUrl,
                address,
                depositedAmount,
                contractAddress,
                dexAddress,
                devAddress,
                dexName,
                marketCap,
                website,
                twitter,
                telegram,
                blockchainLogoUrl,
                tokenPriceChange,
                symbol,
                info
              },
              i
            ) => (
              <animated.div
                key={i}
                style={{
                  ...(i === 0 && prevAnimateRef.current
                    ? firstItemShakeAnimation
                    : '')
                }}
              >
                {prevAnimateRef.current && i === 0 && <AnimatedOverlay />}
                <LaunchpadCard
                  progress={progress}
                  Liquidity={Liquidity}
                  tokenName={tokenName}
                  Logo={
                    <img src={logoUrl} className="claim-card-logo" />
                  }
                  Banner={logoUrl}
                  chadAddress={address}
                  depositedAmount={depositedAmount}
                  contractAddress={contractAddress}
                  devAddress={devAddress}
                  devName={symbol}
                  changePercentage={tokenPriceChange}
                  description={info}
                  marketCap={marketCap}
                  website={website}
                  twitter={twitter}
                  telegram={telegram}
                  BlockchainLogo={
                    <img
                      src={blockchainLogoUrl}
                      className="launchpad-blockchain-logo"
                    />
                  }
                />
              </animated.div>
            )
          )}
      </div>
    )
  }

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen)
    cookies.set('show-how-it-works', 'false')
  }

  const closeModal = e => {
    if (e.target.id === 'modal') {
      setModalIsOpen(false)
      cookies.set('show-how-it-works', 'false')
    }
  }

  const modalContent = (
    <div
      id="modal"
      onClick={closeModal}
      className={`modal ${modalIsOpen ? 'show-modal' : ''}`}
    >
      <div className="modal-content">
        <h1>How it works</h1>
        <p>
          ChadPump.fun is a fun launchpad website that allows anyone to easily
          trade or create their own memecoin on the Avalanche Network in
          minutes.
        </p>
        <p>
          (Fair Launch) Memecoins created via our platform have no presale or
          team allocations. Everyone has equal chance to buy the coin.
        </p>
        <p>
          Disclaimer: trading these tokens is highly risky / speculative. Do not
          invest more than you can afford to lose. Anyone can list a token,
          listing does not mean we endorse the token. Token prices can be
          extremely volatile. Be sure to follow any legal guidelines that your
          country specifies.
        </p>
        <h2>How to participate:</h2>
        <p>Step 1: Pick a token that you like</p>
        <p>Step 2: Buy the coin on the bonding curve</p>
        <p>
          Step 3: If enough people buy on the bonding curve it reaches a
          marketcap $6.9k and the liquidity is then deposited on exchange and
          burned
        </p>
        <p>Step 4: Buy or Sell the token as you see fit</p>
        <button onClick={toggleModal} className="save-button">
          I'm ready to pump
        </button>
      </div>
    </div>
  )

  const FilterSelect = ({ options, defaultValue, onChange }) => {
    const handleChange = newValue => {
      onChange(newValue)
    }

    return (
      <Select
        defaultValue={defaultValue}
        isSearchable={false}
        options={options}
        value={options.find(option => option.value === defaultValue.value)}
        onChange={handleChange}
        styles={{
          control: styles => ({
            ...styles,
            padding: '8px 16px',
            color: 'white',
            backgroundColor: '#151527',
            border: '1px solid rgba(67, 0, 178, 0.4)',
            boxShadow: 'none',
            borderRadius: '4px',
            marginRight: '15px',
            marginTop: '15px',
            cursor: 'pointer',
            outline: 'none',
            '&:hover': {
              borderColor: '#6001ff',
              boxShadow: 'none'
            },
            '&:focus': {
              borderColor: '#6001ff',
              boxShadow: 'none'
            },
            '&:active': {
              borderColor: '#6001ff',
              boxShadow: 'none'
            }
          }),
          option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isFocused ? '#6001ff' : '#151527',
            color: 'white',
            cursor: 'pointer'
          }),
          singleValue: (styles, { isFocused }) => ({
            ...styles,
            color: 'white',
            outline: 'none'
          }),
          indicatorSeparator: styles => ({
            ...styles,
            display: 'none'
          })
        }}
      />
    )
  }

  const loadMoreLists = () => async () => {
    try {
      setLoading(true)
      let newLists = []
      let last
      const length = totalLength - currentIndex
      if (length >= 0) {
        if (length > 0) {
          last = currentIndex + 6
        } else {
          last = currentIndex + length
        }
        for (let i = currentIndex; i < last; i++) {
          newLists.push(allLists[i])
        }
        let newDatas = sortedChadLists.concat(newLists)
        setSortedChadLists(newDatas)
        setCurrentIndex(last)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const FilterIcon = ({ defaultValue, onChange }) => {
    return (
      <button
        onClick={onChange}
        className="leunchpad-sort-options"
        style={{
          width: '54px',
          height: '54px',
          background: '#151527',
          borderRadius: '4px',
          marginTop: '15px',
          marginRight: '15px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer'
        }}
      >
        {orderValue === 'Ascending' ? (
          <img
            src={sortAscendingIcon}
            alt="Sort Ascending"
            style={{ width: '24px', height: '24px' }}
          />
        ) : (
          <img
            src={sortDescendingIcon}
            alt="Sort Descending"
            style={{ width: '24px', height: '24px' }}
          />
        )}
      </button>
    )
  }

  return (
    <div>
      <style jsx>{`
        .modal {
          opacity: 0;
          visibility: hidden;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          z-index: 10;
          overflow-y: auto;
        }

        .show-modal {
          opacity: 1;
          visibility: visible;
        }

        .modal-content {
          background-color: #151527;
          padding: 20px;
          margin: 10px;
          border-radius: 5px;
          border: solid #6001ff 1px;
          text-align: center;
          z-index: 11;
          color: white;
          max-width: 480px;

          overflow-y :auto;
        }
          @media (max-width: 768px) {
          .modal-content {
             height : -webkit-fill-available;
          }
          }

        .search-container {
          position: relative;
          margin-top: 15px;
          margin-right: 56px;
        }

        .search-input {
          width: 90%;
          max-width: 300px;
          padding: 18px 16px;
          color: white;
          background-color: #151527;
          border: 1px solid gray;
          border-radius: 4px;
          outline: none;
          padding-left: 50px; /* space for the icon */
        }

        .search-icon {
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          width: 32px;
          height: 32px;
        }
      `}</style>
      {modalContent}
      <div className="GlobalContainer launches-all-padding">
        <div style={{ zIndex: 1 }}>
          <TopBar animate={animate} />
          <div className="headerMargin" />
          <div className="MainDashboard">
            <div className='HeaderBox'>
              <div>
                <p className="ContractContentTextTitle h1">
                  ChadPump.Fun
                </p>
                <p className="chadContent">
                  The #1 "CHAD" Memecoin Launchpad on the Avalanche "CHAD" Network. Pump to the Moon!
                </p>
                <p className="howitworks" onClick={() => setModalIsOpen(true)}>
                  How it works?
                </p>
              </div>
              <TopPanel />
            </div>

            <div className="layout-container">
              <section className="FairLaunchFlexLayout">
                <section>
                  <div className="launches-switch">
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: '-15px'
                      }}
                    >
                      <div className="search-container">
                        <img
                          src={searchIcon}
                          alt="Search"
                          className="search-icon"
                        />
                        <input
                          type="text"
                          placeholder="Search for tokens"
                          className="search-input"
                          value={searchTerm}
                          onChange={e => setSearchTerm(e.target.value)}
                        />
                      </div>
                      <FilterSelect
                        options={sortOptions}
                        defaultValue={sortOptions.find(
                          option => option.value === sortValue
                        )}
                        onChange={onSortChange}
                      />
                      <FilterIcon
                        defaultValue={
                          orderValue === 'Ascending'
                            ? sortAscendingIcon
                            : sortDescendingIcon
                        }
                        onChange={toggleSortOrder}
                      />
                      <FilterSelect
                        options={statusOptions}
                        defaultValue={statusValue}
                        onChange={onStatusChange}
                      />
                      <div
                        className="Text1"
                        style={{
                          color: 'white',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginTop: '14px'
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={animate}
                          onChange={handleAnimateChange}
                          style={{
                            width: '16px',
                            height: '16px',
                            marginTop: '2px'
                          }}
                        />
                        Animate
                      </div>
                    </div>
                  </div>

                  <br />
                  {sortedChadLists.length > 0 ? (
                    <>
                      {animate ? (
                        <LaunchpadCardGrid
                          items={animatedChadLists}
                          key={animatedChadLists.join(',')}
                        />
                      ) : (
                        <LaunchpadCardGrid
                          items={sortedChadLists}
                          key={sortedChadLists.join(',')}
                        />
                      )}

                      {loading === true ? (
                        <div className="loadingBox">
                          <div className="EmptyLaunchpad">
                            <div className="loadingBox">
                              <p className="Text1" style={{ color: 'white' }}>
                                Loading...
                              </p>
                              <ClipLoader
                                color={'#afccc6'}
                                loading={true}
                                size={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </div>
                          </div>
                        </div>
                      ) : (totalLength - currentIndex) > 0 ? (
                        <p className="loadMoreText" onClick={loadMoreLists()}>
                          Load more ...
                        </p>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <div className="loadingBox">
                      <p className="Text1" style={{ color: 'white' }}>
                        No data yet
                      </p>
                    </div>
                  )}
                  <br />
                  <br />
                </section>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AllLaunches
